import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MDBox from "components/MDBox";
// import Sidenav from "comps/Sidenav";
import Gettheme from "assets/theme";
// import routes from "SideNavLinks";
// import { useMaterialUIController } from "context";
// import Dashboard from "layouts/dashboard";
// import Documentation from "layouts/documentation";
// import Vendors from "layouts/vendors";
// import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
// import Projects from "layouts/projects";
// import Qualification from "layouts/qualification";
import Survey from "layouts/survey";
import AddSurvey from "layouts/survey/components/addSurvey";
import { SnackbarProvider } from "notistack";
// import AddVendors from "layouts/vendors/components/addVendors";
// import VendorsList from "layouts/vendors/components/vendorsList";
// import PrivateOutlet from "components/privateOulet/PrivateOutlet";
// import AddProjects from "layouts/projects/components/addProjects";
// import ProjectsList from "layouts/projects/components/ProjectList";
// import ClientsList from "layouts/clients/components/clientList";
// import Clients from "layouts/clients";
// import AddClients from "layouts/clients/components/addClient";
import SurveyList from "layouts/survey/components/surveyList";
import BasicDetails from "layouts/survey/components/basicDetails";
import QualificationSurvey from "layouts/survey/components/Qualification";
import QuotaSurvey from "layouts/survey/components/QuotaSurvey";
// import SupplierSurvey from "layouts/survey/components/SupplierSurvey";
// import VendorDetails from "layouts/vendors/components/vendorDetails";
// import RedirectType from "layouts/vendors/components/redirectType";
// import ClientBasicDetails from "layouts/clients/components/clientBasicDetails";
// import ClientEncryption from "layouts/clients/components/clientEncryption";
// import SecurityConfig from "layouts/survey/components/securityConfig";
// import RoutingConfig from "layouts/vendors/components/routingConfig";
// import ProjectBasicDetails from "layouts/projects/components/BasicDetails";
// import ProjectSurveyList from "layouts/projects/components/surveyList";
// import SecurityConfiguration from "layouts/clients/components/securityConfiguration";
// import Reports from "layouts/Reports";
// import SurveyReport from "layouts/survey/components/Report";
// import VendorEncryption from "layouts/vendors/components/vendorEncryption";
// import Campaigns from "layouts/campaigns";
// import CampaignsList from "layouts/campaigns/components/campaignsList";
// import AddCampaigns from "layouts/campaigns/components/addCampaigns";
// import CampaignBasicDetails from "layouts/campaigns/components/campaignBasicDetails";
// import Reconciliation from "layouts/projects/components/reconciliation";
// import ReconciliationList from "layouts/projects/components/reconciliation/components/reconciliationList";
// import AddReconciliation from "layouts/projects/components/reconciliation/components/addReconciliation";
// import ClientFilter from "layouts/clients/components/clientFilter";
// import SignUp from "layouts/authentication/sign-up";
// import UserForgotPassword from "layouts/authentication/forget-password";
// import UserSetPassword from "layouts/authentication/set-password";
// import UserUpdatePassword from "layouts/authentication/update-password";
// import CampaignReportList from "layouts/campaigns/components/campaignReportList";
// import SupplierMargin from "layouts/vendors/components/supplierMargin";
// import LanguageList from "layouts/vendors/components/languageList";
// import ClientbuyerConfig from "layouts/vendors/components/clientbuyerConfig";
// import VendorSupplyConfig from "layouts/vendors/components/vendorSupply";
import colors from "assets/theme/base/colors";
import TLinkApi from "services/tlink.api";
// import GlobalReconciliation from "layouts/globalReconciliation";
// import Users from "layouts/users";
// import UsersList from "layouts/users/components/userList";
// import InviteMember from "layouts/users/components/inviteUser";
// import UserBasicDetails from "layouts/users/components/userBasicDetails";
// import UserResetPassword from "layouts/authentication/reset-password";
import Axios from "axios";
import { getDomainName } from "services/utility";
export default function App() {
  // const [controller] = useMaterialUIController();
  // const { layout, sidenavColor } = controller;
  // const [width, setWidth] = useState(72);
  const [menuOpen, setMenuOpen] = useState(false);
  const [defaultColor, setDefaultColor] = useState(colors);
  const [theme, setTheme] = useState(Gettheme(defaultColor));
  // const [displayColor, setDisplayColor] = useState();
  // const [firstLoad, setFirstLoad] = useState(false);

  const getThemeColor = async () => {
    let accountSlug = getDomainName(window.location.hostname);

    let obj = {
      accountSlug: accountSlug,
    };
    try {
      let { data } = await Axios.get(
        TLinkApi.baseUrl + `/theme/${accountSlug}`,
        obj
      );
      localStorage.setItem("theme-details", JSON.stringify(data.data));
      const root = document.documentElement;
      root.style.setProperty("--info", data.data.info);
      root.style.setProperty("--secondary", data.data.secondary);
      root.style.setProperty("--text", "black");
      root.style.setProperty("--infoFocus", data.data.info);
      // root.style.setProperty("--body", data.data.background);
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let accountSlug = getDomainName(window.location.hostname);
    let themeSet = localStorage.getItem("theme-details");
    if (!themeSet) {
      getThemeColor();
    } else {
      let changeCssTheme = JSON.parse(themeSet);
      if (changeCssTheme?.info) {
        const root = document.documentElement;
        root.style.setProperty("--info", changeCssTheme.info);
        root.style.setProperty("--secondary", changeCssTheme.secondary);
        root.style.setProperty("--text", "black");
        root.style.setProperty("--infoFocus", changeCssTheme.info);
        // root.style.setProperty("--body", changeCssTheme.background);
      }
    }
    let favObj = document.getElementById("favicon");
    favObj.href = `/opiniobox.png`;

    let aFavObj = document.getElementById("apple-favicon");
    aFavObj.href = `/opiniobox.png`;

    let title = document.getElementById("page-title");
    title.innerHTML = accountSlug;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <MDBox
        sx={{
          display: { xs: "none", md: "unset", lg: "unset" },
        }}
        onClick={() => {
          setMenuOpen(false);
        }}
      >
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          autoHideDuration={2000}
        >
          <CssBaseline />
          {/* {layout === "dashboard" && (
            <Sidenav color={sidenavColor} routes={routes} setWidth={setWidth} width={width} setMenuOpen={setMenuOpen} />
          )} */}
          <Routes>
            {/* <Route
              element={
                <PrivateOutlet setWidth={setWidth} width={width} setMenuOpen={setMenuOpen} menuOpen={menuOpen}  />
              }
            > */}
            {/* <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/globalreconciliation" element={<GlobalReconciliation />} />
              <Route path="/documentation" element={<Documentation />} />
              <Route path="/vendors" element={<Vendors />}>
                <Route index element={<VendorsList />} />
                <Route element={<AddVendors />}>
                  <Route path="/vendors/:id" element={<VendorDetails />} />
                  <Route path="/vendors/:id/edit" element={<VendorDetails />} />
                  <Route path="/vendors/new" element={<VendorDetails />} />
                  <Route path="/vendors/:id/routingConfig/edit" element={<RoutingConfig />} />
                  <Route path="/vendors/:id/vendorEncryption/edit" element={<VendorEncryption />} />
                  <Route path="/vendors/:id/redirectionType/edit" element={<RedirectType />} />
                  <Route path="/vendors/:id/suppliermargin/edit" element={<SupplierMargin />} />
                  <Route path="/vendors/:id/languagelist/edit" element={<LanguageList />} />
                  <Route path="/vendors/:id/clientbuyerconfig/edit" element={<ClientbuyerConfig />} />
                  <Route path="/vendors/:id/vendorSupplyConfig/edit" element={<VendorSupplyConfig />} />
                </Route>
              </Route> */}
            {/* <Route path="/projects" element={<Projects />}>
                <Route index element={<ProjectsList />} />
                <Route element={<AddProjects />}>
                  <Route path="/projects/:id" element={<ProjectBasicDetails />} />
                  <Route path="/projects/:id/edit" element={<ProjectBasicDetails />} />
                  <Route path="/projects/new" element={<ProjectBasicDetails />} />
                  <Route path="/projects/:id/surveyList" element={<ProjectSurveyList />} />
                  <Route path="/projects/:id/reconciliations" element={<Reconciliation />}>
                    <Route index element={<ReconciliationList />} />
                    <Route path="/projects/:id/reconciliations/:reconcileId" element={<AddReconciliation />} />
                    <Route path="/projects/:id/reconciliations/new" element={<AddReconciliation />} />
                  </Route>
                </Route>
              </Route> */}

            {/* <Route path="/clients" element={<Clients />}>
                <Route index element={<ClientsList />} />
                <Route element={<AddClients />}>
                  <Route path="/clients/:id" element={<ClientBasicDetails />} />
                  <Route path="/clients/:id/edit" element={<ClientBasicDetails />} />
                  <Route path="/clients/new" element={<ClientBasicDetails />} />
                  <Route path="/clients/:id/clientEncryption/edit" element={<ClientEncryption />} />
                  <Route path="/clients/new" element={<ClientFilter />} />
                  <Route path="/clients/:id/clientFilters/edit" element={<ClientFilter />} />
                  <Route path="/clients/:id/securityConfiguration/edit" element={<SecurityConfiguration />} />
                </Route>
              </Route> */}

            <Route path="/surveys" element={<Survey />}>
              <Route index element={<SurveyList />} />
              <Route element={<AddSurvey />}>
                <Route path="/surveys/:id" element={<BasicDetails />} />
                <Route path="/surveys/:id/edit" element={<BasicDetails />} />
                {/* <Route path="/surveys/new" element={<BasicDetails />} /> */}
                <Route
                  path="/surveys/:id/qualification/edit"
                  element={<QualificationSurvey />}
                />
                <Route
                  path="/surveys/:id/quota/edit"
                  element={<QuotaSurvey />}
                />
                {/* <Route path="/surveys/:id/supplier/edit" element={<SupplierSurvey />} />
                  <Route path="/surveys/:id/securityConfig/edit" element={<SecurityConfig />} />
                  <Route path="/surveys/:id/report/edit" element={<SurveyReport />} /> */}
              </Route>
            </Route>
            {/* <Route path="/campaigns" element={<Campaigns />}>
                <Route index element={<CampaignsList />} />
                <Route element={<AddCampaigns />}>
                  <Route path="/campaigns/:id" element={<CampaignBasicDetails />} />
                  <Route path="/campaigns/:id/edit" element={<CampaignBasicDetails />} />
                  <Route path="/campaigns/new" element={<CampaignBasicDetails />} />
                  <Route path="/campaigns/:id/report" element={<CampaignReportList />} />
                </Route>
              </Route> */}

            {/* <Route path="/qualification" element={<Qualification />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/profile" element={<Profile />} /> */}
            <Route path="*" element={<Navigate to="/surveys" />} />

            {/* <Route path="/users" element={<Users />}>
                <Route index element={<UsersList />} />
                <Route element={<InviteMember />}>
                  <Route path="/users/:id" element={<UserBasicDetails />} />
                  <Route path="/users/:id/edit" element={<UserBasicDetails />} />
                  <Route path="/users/new" element={<UserBasicDetails />} />
                </Route>
              </Route>
            </Route> */}
            <Route path="/login" element={<SignIn />} />
            {/* <Route path="/signup" element={<SignUp />} />
            <Route path="/set-password" element={<UserSetPassword />} />
            <Route path="/reset-password" element={<UserResetPassword />} />
            <Route path="/forget-password" element={<UserForgotPassword />} />
            <Route path="/update-password" element={<UserUpdatePassword />} /> */}
          </Routes>
        </SnackbarProvider>
      </MDBox>
      <MDBox
        sx={{
          display: { xs: "flex", md: "none", lg: "none" },
          width: "100%",
          height: "100vh",
        }}
        bgColor="rgb(242, 247, 236)"
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontWeight="600"
      >
        Please Open in Tab or Laptop
      </MDBox>
    </ThemeProvider>
  );
}
