import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  ListSubheader,
  TextField,
  Tooltip,
  Icon,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
import SearchInput from "components/searchInput";
import MDTypography from "components/MDTypography";
import GetListOfClients from "hooks/ListOfClients/GetListOfClients";
import GetSurveyStatusList from "hooks/SurveyStatusList/GetSurveyStatusList";
import moment from "moment";
import csvDownload from "json-to-csv-export";
// import Union2 from "assets/images/Union2.png";
import Colors from "../../../../assets/theme/base/colors";
// import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import GetProjectManagerList from "hooks/ProjectManagerList/GetProjectManagerList";
import GetLanguageList from "hooks/LanguageList/GetLanguageList";
import DownloadIcon from "@mui/icons-material/Download";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import useTableTheme from "constant/TableTheme";
import { Stack } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import GetSurveyBasicList from "hooks/SurveyBasicList/GetSurveyBasicList";
import { Box, Button, Backdrop, CircularProgress } from "@mui/material";

const { grey } = Colors;

function SurveyList({ projectId }) {
  const navigate = useNavigate();
  const { setSurvey, setIsEdit } = useOutletContext();
  const { clients } = GetListOfClients();
  const { surveyStatusList } = GetSurveyStatusList();
  const { projectManager, setMangerSearch, mangerSearch } =
    GetProjectManagerList();
  const { language } = GetLanguageList();

  const [filterLanguage, setFilterLanguage] = useState([]);
  const [languageSearch, setLanguageSearch] = useState("");
  const [sorting, setSorting] = useState([]);

  const tableTheme = useTableTheme();

  const {
    surveys,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    languageFilter,
    setLanguageFilter,
    projectMangerFilter,
    setProjectMangerFilter,
    clientFilter,
    setClientFilter,
    filter,
    setFilter,
    dummyPage,
    setSortBy,
    setSortOrder,
  } = GetSurveyBasicList(2, -1, projectId ? projectId : "");
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50, //customize the default page size
  });

  useEffect(() => {
    setData(surveys);
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);

    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        setSortBy(sorting[0].id);
        setSortOrder("asc");
      } else {
        setSortBy(sorting[0].id);
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("id");
      setSortOrder("asc");
    }
  }, [surveys, pagination, sorting]);

  useEffect(() => {
    if (languageSearch === "") {
      setFilterLanguage(language);
    } else {
      setFilterLanguage(
        language?.filter((item) =>
          item.name.toLowerCase().includes(languageSearch.toLowerCase())
        )
      );
    }
  }, [languageSearch, language]);

  let columns = [
    {
      accessorKey: "id",
      header: "Id",

      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ cell }) => {
        const id = cell.getValue();
        return id ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsEdit({
                disabled: true,
                edit: false,
              });
              navigate(`/surveys/${cell.getValue()}`);
            }}
          >
            {cell.getValue()}
          </div>
        ) : null;
      },
    },

    {
      accessorKey: "surveyName",
      header: "Survey Name",

      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      enableSorting: false,
      headerAlign: "center",
    },

    // {
    //   accessorKey: "clientName",
    //   header: "Client Name",
    //   maxSize: 400,
    //   minSize: 180,
    //   size: 180,
    //   muiTableHeadCellProps: {
    //     align: "left",
    //   },
    //   muiTableBodyCellProps: {
    //     align: "left",
    //   },
    //   enableSorting: false,
    //   headerAlign: "center",
    // },

    {
      accessorKey: "sampleRequired",
      header: "Samp. Req.",

      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      headerAlign: "center",
    },
    {
      accessorKey: "countryCode",
      header: "Country",

      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      enableSorting: false,
      headerAlign: "center",
    },
    {
      accessorKey: "loi",
      header: "Loi(mins)",

      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      headerAlign: "center",
    },

    {
      accessorKey: "ir",
      header: "IR(%)",

      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      headerAlign: "center",
    },
    {
      accessorKey: "cpi",
      header: "CPI($)",

      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      headerAlign: "center",
      Cell: ({ cell }) => {
        const cpi = parseFloat(cell.getValue());
        const roundedCpi = Math.round(cpi * 100) / 100;
        return roundedCpi ? roundedCpi.toFixed(2) : null;
      },
    },
    // {
    //   accessorKey: "starts",
    //   header: "Starts",
    //   maxSize: 400,
    //   minSize: 140,
    //   size: 140,
    //   muiTableHeadCellProps: {
    //     align: "left",
    //   },
    //   muiTableBodyCellProps: {
    //     align: "left",
    //   },
    //   headerAlign: "center",
    // },

    // {
    //   accessorKey: "completes",
    //   header: "Completes",
    //   maxSize: 400,
    //   minSize: 160,
    //   size: 160,
    //   muiTableHeadCellProps: {
    //     align: "left",
    //   },
    //   muiTableBodyCellProps: {
    //     align: "left",
    //   },
    //   headerAlign: "center",
    // },
    // {
    //   accessorKey: "epc",
    //   header: "EPC",
    //   maxSize: 400,
    //   minSize: 140,
    //   size: 140,
    //   muiTableHeadCellProps: {
    //     align: "left",
    //   },
    //   muiTableBodyCellProps: {
    //     align: "left",
    //   },
    //   headerAlign: "center",
    //   Cell: ({ renderedCellValue, row }) => {
    //     return row
    //       ? parseFloat(
    //         row?.original?.starts <= 0
    //           ? 0
    //           : row?.original?.revenue / row?.original?.starts
    //       ).toFixed(2)
    //       : null;
    //   },
    // },
    // {
    //   accessorKey: "conversion",
    //   header: "Conv(%)",
    //   maxSize: 400,
    //   minSize: 140,
    //   size: 140,
    //   muiTableHeadCellProps: {
    //     align: "left",
    //   },
    //   muiTableBodyCellProps: {
    //     align: "left",
    //   },
    //   headerAlign: "center",
    //   Cell: ({ renderedCellValue, row }) => {
    //     if (row) {
    //       return (
    //         <div style={{ width: "20px" }}>
    //           {parseFloat(
    //             row?.original?.starts <= 0
    //               ? 0
    //               : row?.original?.completes / row?.original?.starts
    //           ).toFixed(2)}
    //         </div>
    //       );
    //     } else {
    //       return null;
    //     }
    //   },
    // },
    // {
    //   accessorKey: "revenue",
    //   header: "Revenue",
    //   maxSize: 400,
    //   minSize: 140,
    //   size: 140,
    //   muiTableHeadCellProps: {
    //     align: "left",
    //   },
    //   muiTableBodyCellProps: {
    //     align: "left",
    //   },
    //   headerAlign: "center",
    //   Cell: ({ cell }) => {
    //     const revenue = parseFloat(cell.getValue());
    //     if (revenue) {
    //       const roundedRevenue = Math.round(revenue * 100) / 100;
    //       return roundedRevenue.toFixed(2);
    //     } else {
    //       return "NA";
    //     }
    //   },
    // },

    {
      accessorKey: "createdAt",
      header: "Created at",
      maxSize: 400,
      minSize: 160,
      size: 160,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      headerAlign: "center",
      Cell: ({ cell }) => {
        const createdAtTime = moment(cell.getValue());
        const formattedDateTime = createdAtTime.format(
          "Do MMM YYYY, h:mm:ss a"
        );
        const truncatedName =
          formattedDateTime?.length > 8
            ? formattedDateTime?.substring(0, 8) + "..."
            : formattedDateTime;
        return data ? (
          <Tooltip title={cell.getValue()} placement="bottom-start">
            <div>{truncatedName}</div>
          </Tooltip>
        ) : null;
      },
    },

    {
      accessorKey: "lastCompleted",
      header: "Last Complete",
      maxSize: 400,
      minSize: 180,
      size: 180,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      headerAlign: "center",
      Cell: ({ cell }) => {
        const lastCompletedTime = moment(cell.getValue());
        if (!lastCompletedTime.isValid() || data?.lastCompleted === null) {
          return <div>NA</div>;
        }
        const formattedDateTime = lastCompletedTime.format(
          "Do MMM YYYY, h:mm:ss a"
        );
        const truncatedName =
          formattedDateTime?.length > 8
            ? formattedDateTime?.substring(0, 8) + "..."
            : formattedDateTime;
        return data ? (
          <Tooltip title={cell.getValue()} placement="bottom-start">
            <div>{truncatedName}</div>
          </Tooltip>
        ) : null;
      },
    },

    {
      accessorKey: "surveyStatusName",
      header: "Status",
      maxSize: 400,
      minSize: 140,
      size: 140,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      headerAlign: "center",
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        navigate(`/surveys/${row.original.id}`);
      },
      sx: {
        cursor: "pointer",
      },
    }),
    manualPagination: false,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableGlobalFilter: true,
    manualSorting: false,
  });

  const logoutUser = () => {
    // Auth.remove("auth");
    localStorage.removeItem("auth");
    setTimeout(function () {
      navigate("/login");
    }, 1000);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox pb={3} width="100%">
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingInline="1rem"
        >
          <MDTypography color="secondary">Surveys</MDTypography>
          <Button
            color="secondary"
            onClick={() => {
              logoutUser();
            }}
            sx={{
              background: grey[100],
              borderRadius: "4px !important",
              "&:hover": {
                background: grey[100],
              },
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Icon fontSize="small"> logout_icon</Icon>
              <MDTypography color="secondary" fontSize="0.8rem">
                Log Out
              </MDTypography>
            </Stack>
          </Button>
        </MDBox>
        <MDBox display="flex" justifyContent="center">
          <MDBox mt={3} borderRadius="5px" bgColor="white" width="98%">
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                table={table}
                style={{
                  width: "100%",
                }}
              />
            </ThemeProvider>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
}

export default SurveyList;
