import { Outlet } from "react-router-dom";
import React, { useState } from "react";
import MDBox from "components/MDBox";

function Survey() {
  const [survey, setSurvey] = useState({});
  const [isEdit, setIsEdit] = useState({ disabled: false, edit: false });
  return (
    <MDBox paddingTop="1rem">
      <Outlet context={{ survey, setSurvey, setIsEdit, isEdit }} />
    </MDBox>
  );
}
export default Survey;
